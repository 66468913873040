<template>
  <div class="footerCopyright row justify-content-between">
    <div class="col-12 col-sm-5 col-md-4">
      {{footerText.copyright}}
    </div>
    <div class="col-12 col-sm-5 col-md-4">
      <span v-for="footerBtn in footerText.footerButtons" :key="footerBtn.id">
        <a class="footerNavigation" v-bind:href="footerBtn.linksTo" :target="footerBtn.target">{{footerBtn.text}}</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerCopyright",
  props: {
    footerText: Object
  }
}
</script>

<style scoped>

</style>