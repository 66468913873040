<template>
  <div class="kontaktformular kontakt row justify-content-center justify-content-lg-end pbSpace mtSpace">
    <div class="col-10 col-lg-6">
      <div class="row justify-content-center justify-content-lg-start">
        <div class="col-10 col-sm-11 justify-content-center justify-content-lg-start formHeader">
          <div class="headerSpan justify-content-center">
            <h4>{{formItems.title}}</h4>
            <span class="headliner"></span>
          </div>
          <p>{{formItems.text}}</p>
        </div>
        <div class="col-10 col-sm-11">
          <form method="POST">
            <div class="form-group">
              <span ref="lengthDescription" class="maxlengthDescription">noch {{availableCharacters}} Zeichen</span>
              <textarea ref="customerQuestion" type="text" required="required" v-model="form.question" class="form-control rounded customerQuestion" :placeholder="'' + formItems.placeholder.formInput" maxlength="400" @keyup="typedCharacters"></textarea>
            </div>
            <button type="submit" class="btn rounded">Senden</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Frageformular",
  data() {
    return {
      form: {
        telNr: '',
        question: ''
      },
      availableCharacters: 400
    }
  },
  props: {
    formItems: Object,
  },
  methods: {
    typedCharacters() {
      this.availableCharacters = 400;
      let typeChar = this.$refs.customerQuestion.value.length
      this.availableCharacters -= typeChar;
    }
  }
}
</script>

<style scoped>

</style>