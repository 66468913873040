<template>
  <div class="einzelpreise pbSpace">
    <div class="row justify-content-center">
      <div class="col-10 col-md-7 col-lg-5 col-xl-5 justify-content-center justify-title">
        <h4>{{indivCards.title}}</h4>
        <span class="headliner"></span>
        <p>{{indivCards.text}}</p>
      </div>
      <div class="w-100"></div>
  <!--table for timed tickets-->
      <div class="rounded col-8 col-md-9 col-lg-7 col-xl-10">
        <table class="table table-borderless table-responsive">
          <tr class="mainRow single">
            <td ref="serviceSingle" class="serviceSingle" v-for="fitCard in indivCards.cards" :key="fitCard.card">
              <thead>
              <tr class="justify-content-end">
                <th class="serviceHead individual" scope="col">{{fitCard.card}}</th>
              </tr>
              </thead>
              <tbody>
              <tr class="justify-content-start">
                <td class="serviceBody individual">{{fitCard.price}}</td>
              </tr>
              </tbody>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row justify-content-center mtSpace">
      <div class="col-10 col-md-7 col-lg-5 col-xl-5 justify-content-center justify-title">
        <h4>{{indivServices.title}}</h4>
        <span class="headliner"></span>
        <p>{{indivServices.text}}</p>
      </div>
      <div class="w-100"></div>
  <!--table for individual services part 1-->
      <div class="col-8 col-md-9 col-lg-7 col-xl-10 rounded">
      <div class="rounded fuseDown">
        <table class="table table-borderless table-responsive">
          <tr class="mainRow">
            <td ref="serviceSingle" class="serviceSingle" v-for="fitServe in indivServices.servicesA" :key="fitServe.service">
              <thead>
              <tr class="justify-content-start">
                <th class="serviceHead individual" scope="col" v-html="fitServe.service"></th>
              </tr>
              </thead>
              <tbody>
              <tr class="justify-content-start">
                <td class="serviceBody individual">{{fitServe.price}}</td>
              </tr>
              </tbody>
            </td>
          </tr>
        </table>
      </div>
      <div class="w-100"></div>
  <!--table for individual services part 2 fusing on small devices together with prior table-->
      <div class="rounded fuseUp">
        <table class="table table-borderless table-responsive">
          <tr class="mainRow">
            <td ref="serviceSingle" class="serviceSingle" v-for="fitServe in indivServices.servicesB" :key="fitServe.service">
              <thead>
              <tr class="justify-content-start">
                <th class="serviceHead individual" scope="col" v-html="fitServe.service"></th>
              </tr>
              </thead>
              <tbody>
              <tr class="justify-content-start">
                <td class="serviceBody individual">{{fitServe.price}}</td>
              </tr>
              </tbody>
            </td>
          </tr>
        </table>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndividualLeistungen",
  props: {
    indivCards: Object,
    indivServices: Object
  },
}
</script>

<style scoped>

</style>