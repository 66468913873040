<template>
  <div id="app" class="container-fluid">
    <ImageHeader :logo="logo"/>

    <router-view/>

    <footerCopyright :footerText="footerText"/>
  </div>
</template>

<script>
import ImageHeader from "@/components/ImageHeader";
import footerCopyright from "@/components/footerCopyright";

export default {
  name: 'App',
  components: {
    ImageHeader,
    footerCopyright
  },
  data() {
    return {
      logo: 'bodyzentrum_logo_white.svg',
      footerText: {
        copyright: '© 2022 Bodyzentrum Langenselbold',
        footerButtons: [
          {text: 'Impressum', linksTo: '/impressum', target: ""},
          {text: 'Datenschutz', linksTo: '/privacy', target: ""},
          {text: 'Hausordnung', linksTo: '/Bodyzentrum_Hausordnung_2022.pdf', target: "_blank"}
        ]
      }
    }
  }
}
</script>

<style lang="scss">
@import "src/styles/main";
</style>