<template>
  <div class="imageHeader">
    <div class="brandLogo">
      <img v-bind:src="require('../assets/' + logo)" alt="Bodyzentrum Langenselbold">
    </div>
    <div class="col-12 imageBody">
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageHeader",
  props: {
    logo: String,
  }
}
</script>

<style scoped>

</style>