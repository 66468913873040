<template>
  <div class="navComponent">
    <div>
      <button class="toggle-sidebar" type="button" :class="{active: isActive}" @click="isActive = !isActive">
        <span></span><span></span><span></span>
      </button>
    </div>
    <div class="sidebar" :class="{active: isActive}">
      <div class="linkDiv left">
        <a v-for="link in leftNavLinks" :key="link.id" class="nav-link" :class="{active: active_nav === link.id}" @click="scrollToComponent(link.id)" :href="'#' + link.id">{{link.text}}</a>
      </div>
      <div class="linkDiv right">
        <a v-for="link in rightNavLinks" :key="link.id" class="nav-link" :class="{active: active_nav === link.id}" @click="scrollToComponent(link.id)" :href="'#' + link.id">{{link.text}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import smoothscroll from "smoothscroll-polyfill";

export default {
  name: "Navigation",
  data() {
    return {
      active_nav: this.navigationText[0].id,
      isActive: false
    }
  },
  props: {
    navigationText: Array,
    logo: String
  },
  computed: {
    leftNavLinks: function () {
      return this.navigationText.filter(link => link.position === 'left');
    },
    rightNavLinks: function () {
      return this.navigationText.filter(link => link.position === 'right');
    }
  },
  methods: {
    scrollToComponent(selector) {
      this.active_nav = selector;
      smoothscroll.polyfill();
      document.querySelector('.' + selector).scrollIntoView({behavior: "smooth"})
    },
  }
}
</script>

<style scoped>

</style>