<template>
  <div class="home">
    <Navigation :navigationText="navigationText" :logo="logo"/>
    <Fitnesspakete :iconCheck="iconCheck" :fitnessPackages="fitnessPackages"/>
    <Einzelpreise :indivCards="fitnessCards" :indivServices="furtherServices"/>
    <Kontaktformular :formItems="questionForm"/>
    <AddressMap :businessHours="businessHours" :logo="logo" :address="address"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Fitnesspakete from '@/components/Fitnesspakete.vue'
import Einzelpreise from "@/components/Einzelpreise";
import Kontaktformular from "@/components/Kontaktformular";
import Navigation from "@/components/Navigation";
import AddressMap from "@/components/AddressMap";

export default {
  name: 'Home',
  components: {
    AddressMap,
    Navigation,
    Fitnesspakete,
    Einzelpreise,
    Kontaktformular
  },
  data() {
    return {
      logo: 'bodyzentrum_logo_white.svg',
      navigationText: [
        {text: 'Fitnesspakete', id: 'fitnesspakete', position: 'left'},
        {text: 'Einzelpreise', id: 'einzelpreise', position: 'left'},
        {text: 'Kontakt', id: 'kontakt', position: 'right'},
        {text: 'Öffnungszeiten', id: 'gMap', position: 'right'}
      ],

      iconCheck: 'check-solid.svg',
      fitnessPackages: {
        title: "Unsere Fitnesspakete",
        label: 'Fitnesspakete',
        rate: '(Monatsbeiträge)',
        termLabel: 'Vertragslaufzeit',
        packages: [
          {
            displayUnchecked: false,
            name: 'Standard 1',
            price: '35 €',
            term: '1 Monat',
            termComment: 'Vertragsverlängerung autom. immer 1 Monat nach Ablauf',

            services: [
              {label: 'Gerätenutzung', active: true},
              {label: 'Duschen frei', active: true},
              {label: 'Trainingsplan', active: false},
              {label: 'Ernährungsplan', active: false},
              {label: 'Kalorienbedarfsrechnung', active: false},
              {label: 'Körperfettmessung (14-tägig)', active: false},
              {label: 'Personaltraining (3x/Woche)', active: false}
            ]
          },
          {
            displayUnchecked: false,
            label: 'Fitnesspakete',
            rate: '(Monatsbeiträge)',
            name: 'Standard 2',
            price: '30 €',
            termLabel: 'Vertragslaufzeit',
            term: '1 Jahr',
            termComment: 'Vertragsverlängerung autom. immer 1 Monat nach Ablauf',
            services: [
              {label: 'Gerätenutzung', active: true},
              {label: 'Duschen frei', active: true},
              {label: 'Trainingsplan', active: false},
              {label: 'Ernährungsplan', active: false},
              {label: 'Kalorienbedarfsrechnung', active: false},
              {label: 'Körperfettmessung (14-tägig)', active: false},
              {label: 'Personaltraining (3x/Woche)', active: false}
            ]
          },
          {
            displayUnchecked: true,
            label: 'Fitnesspakete',
            rate: '(Monatsbeiträge)',
            name: 'Premium',
            price: '99 €',
            hint: 'Bei Mitgliedern mit bestehendem Jahresvertrag, wird der Monatsbeitrag abgezogen',
            termLabel: 'Vertragslaufzeit',
            term: 'mind. 3 Monate',
            termComment: 'Vertragsverlängerung nach Vereinbarung',
            services: [
              {label: 'Gerätenutzung', active: true},
              {label: 'Duschen frei', active: true},
              {label: 'Trainingsplan', active: true},
              {label: 'Ernährungsplan', active: true},
              {label: 'Kalorienbedarfsrechnung', active: true},
              {label: 'Körperfettmessung (14-tägig)', active: true},
              {label: 'Personaltraining (3x/Woche)', active: false}
            ]
          },
          {
            displayUnchecked: true,
            label: 'Fitnesspakete',
            rate: '(Monatsbeiträge)',
            name: 'Premium Plus',
            price: '499 €',
            hint: 'Bei Mitgliedern mit bestehendem Jahresvertrag, wird der Monatsbeitrag abgezogen',
            termLabel: 'Vertragslaufzeit',
            term: 'mind. 3 Monate',
            termComment: 'Vertragsverlängerung nach Vereinbarung',
            services: [
              {label: 'Gerätenutzung', active: true},
              {label: 'Duschen frei', active: true},
              {label: 'Trainingsplan', active: true},
              {label: 'Ernährungsplan', active: true},
              {label: 'Kalorienbedarfsrechnung', active: true},
              {label: 'Körperfettmessung (14-tägig)', active: true},
              {label: 'Personaltraining (3x/Woche)', active: true}
            ]
          },
        ],
      },

      fitnessCards: {
        title: 'Du möchtest individuell entscheiden?',
        text: 'Wir bieten auch Einmalbeiträge, wie Tages-, Zehner- oder Monatskarten an, damit musst Du Dich nicht gleich für ein Fitnesspaket entscheiden.',
        cards: [
          {card: 'Tageskarte', price: '7,50 €'},
          {card: '10er Karte', price: '50 €'},
          {card: '1 Monatskarte', price: '40 €'},
          {card: '3 Monatskarte', price: '110 €'}
        ]
      },

      furtherServices: {
        title: 'Weitere Leistungen',
        text: 'Du möchtest Dein vorhandenes Programm ergänzen? Kein Problem! Egal ob Personaltraining, Trainingsplan oder Körperfettmessung. Du entscheidest, was am Besten zu Dir und Deinen Bedürfnissen passt. Wir unterstützen Dich dabei.',
        servicesA: [
          {service: 'Trainingsplan', price: '69 €'},
          {service: 'Kalorienbedarfsrechnung (KBR)', price: '5 €'},
          {service: 'Körperfettmessung (KFM)', price: '10 €'},
          {service: 'Ernährungsplan<br>Inkl. KFM & KBR', price: '129 €'}
        ],
        servicesB: [
          {service: 'Personaltraining pro Einheit', price: '49 €'},
          {service: 'Personaltraining 10er Karte', price: '390 €'}
        ]
      },

      questionForm: {
        title: 'Du hast Fragen?',
        text: 'Das Bodyzentrum bietet Dir persönliche Unterstützung und Hilfestellung rund um die Themen Fitness und Training. Du hast eine Frage zu Deinem Trainingsplan, willst etwas zu den Fitnesspaketen wissen oder uns einfach nur Dein Feedback mitteilen? Dann benutze das unten stehenden Kontaktformular. Wenn Du uns deine Kontaktdaten hinterlässt, melden wir uns so schnell es geht bei Dir.',
        placeholder: {
          contact: 'Deine Telefon/Handynummer',
          formInput: 'Deine Frage eingeben'
        },
      },

      address: [
        {icon: "location-dot-solid.svg", text: "Gelnhäuser Str. 35", text2: "63505 Langenselbold"},
        {icon: "phone-solid.svg", text: "06184 - 62 0 14"},
        {icon: "whatsapp.svg", text: "0176 - 34611643"},
        {icon: "envelope-solid.svg", text: "dorant_markus@yahoo.de"},
      ],

      businessHours: {
        title: 'Unsere Öffnungszeiten',
        business: [
          {day: 'Montag, Mittwoch, Freitag', hour: '09:00 - 12:00 Uhr', hour2: '14:00 - 21:00 Uhr'},
          {day: 'Dienstag, Donnerstag', hour: '07:00 - 12:00 Uhr', hour2: '14:00 - 21:00 Uhr'},
          {day: 'Samstag', hour: '10:00 - 16:00 Uhr'},
          {day: 'Sonntag', hour: '09:00 - 13:00 Uhr'},
          {day: '<span class="font-italic"><sup style="top: 0; font-size: 1em;">*</sup> Feiertage nach Aushang</span>'}
        ]
      }
    }
  }
}
</script>
