<template>
  <div class="addressMap row justify-content-around align-items-end">
<!--    <div class="business col-12 gMap">-->
<!--      <GoogleMap></GoogleMap>-->
<!--    </div>-->
    <div class="col col-10 col-md-5 pbSpace">
      <table>
        <tr class="title">
          <td colspan="2">
            <img v-bind:src="require('../assets/' + logo)" alt="Bodyzentrum Langenselbold">
          </td>
        </tr>
        <tr v-for="addressPart in address" :key="addressPart.icon">
          <td><img v-bind:src="require('../assets/' + addressPart.icon)" alt=""/></td>
          <td><p>{{addressPart.text}}</p> <p>{{addressPart.text2}}</p></td>
        </tr>
      </table>
    </div>
    <div class="col col-10 col-md-5 pbSpace">
      <table>
        <tr class="title">
          <td colspan="2">
            <h6>{{businessHours.title}}</h6>
          </td>
        </tr>
        <tr v-for="daySpan in businessHours.business" :key="daySpan.day">
          <td><p v-html="daySpan.day"></p></td>
          <td><p>{{daySpan.hour}}</p> <p v-if="daySpan.hour2 !== undefined">{{daySpan.hour2}}</p> </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
// import GoogleMap from "@/components/GoogleMap";
export default {
  name: "AddressMap",
  // components: {GoogleMap},
  props: {
    address: Array,
    businessHours: Object,
    logo: String
  }
}
</script>
