<template>
  <div class="fitnesspakete row justify-content-center pbSpace">
    <div class="col-8 col-sm-12 justify-content-center justify-title">
      <h4>{{ fitnessPackages.title }}</h4>
      <span class="headliner"></span>
    </div>
    <div class="w-100"></div>
    <!-- big table only visible on big devices -->
    <div ref="bigTable" class="rounded col-10 showBigTable">
      <table class="table table-borderless table-responsive">
          <tr>
            <th class="fitService">
              {{ fitnessPackages.label }}<br>
              {{ fitnessPackages.rate }}
            </th>
            <th class="serviceHead" v-for="p in fitnessPackages.packages" :key="p.name">
              {{p.name}} <br/>
              <h3>{{p.price}}<template v-if="p.hint"> *</template></h3>
              <p v-if="p.hint" class="hint">* {{ p.hint}}</p>
            </th>
          </tr>
          <tr>
            <td class="fitService">
              {{fitnessPackages.termLabel}}
            </td>
            <td class="serviceCheck tableBig" v-for="p in fitnessPackages.packages" :key="p.name">
              <div>{{p.term}}</div>
              <span class="termComment">{{p.termComment}}</span>
            </td>
          </tr>
          <tr v-for="service in fitnessPackages.packages[0].services" :key="service.label">
            <td class="fitService label">{{service.label}}</td>
            <td class="serviceCheck tableBig" v-for="p in fitnessPackages.packages" :key="p.name">
              <img v-if="findService(p, service.label)" :src="require('../assets/'+iconCheck)" alt="">
              <template v-else>-</template>
            </td>
          </tr>
      </table>
    </div>
    <!-- small table only visible on small devices -->
    <div class="rounded col-8 col-md-5 showSmallTable" v-for="fitPack in fitnessPackages.packages" :key="fitPack.name">
      <table class="table table-borderless table-responsive">
          <tr>
          <!-- Adding name, price and contractTerm to small table as head -->
            <th class="fitService head" scope="col">
              {{ fitPack.name }} <br/>
              <h3>{{ fitPack.price }} <template v-if="fitPack.hint">*</template></h3>
              <p v-if="fitPack.hint" class="hint">* {{ fitPack.hint}}</p>
              <div>{{ fitPack.termLabel }} {{ fitPack.term }}</div>
              <span class="termComment">{{ fitPack.termComment }}</span>
            </th>
          </tr>
          <!-- Adding all services to small table -->
          <tr v-for="service in fitPack.services" :key="service.label">
            <td v-if="fitPack.displayUnchecked || service.active" class="serviceCheck tableSmall">
              <img v-if="service.active" :src="require('../assets/'+iconCheck)" alt="">
              <template v-else>-</template>
              <div>{{ service.label }}</div>
            </td>
          </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Fitnesspakete",
  props: {
    fitnessPackages: Object,
    iconCheck: String
  },
  methods: {
    findService(p, label) {
      let current = p.services.find(element => element.label === label)

      if (current === undefined) {
        return false;
      }

      return current.active;
    }
  }
}
</script>

<style scoped>

</style>