import { render, staticRenderFns } from "./Einzelpreise.vue?vue&type=template&id=505852ec&scoped=true&"
import script from "./Einzelpreise.vue?vue&type=script&lang=js&"
export * from "./Einzelpreise.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "505852ec",
  null
  
)

export default component.exports